/**
* 路由数组，后期会从接口获取
* @param {*} auth 是否需要登录
* @param {*} directory 渲染组件
* @param {*} exact 路由是否严格匹配
* @param {*} name 组件名称
* @param {*} path 路由
*/
interface IRouter {
    basic: object,
    login: object,
    layout: object
}

export const Router: any = {
    // 对应BasicLayout
    basic: {
      reportUpdate: {
          directory: 'pages/Credit/Upload',
          name: '上传报告',
          path: '/credit/upload'
      },
      companyUpload: {
        directory: 'pages/Credit/UploadCompany',
        name: '上传报告',
        path: '/credit/uploadCompany'
      },      
      reportTabList: {
        directory: 'pages/Credit/TabList',
        name: '首页',
        path: ''
      },      
      reportCompanyList: {
        directory: 'pages/Credit/CompanyList',
        name: '企业征信报告',
        path: ''
      },
      reportList: {
        directory: 'pages/Credit/List',
        name: '个人征信报告',
        path: ''
      },
    },
    // 对应LoginLayout
    login: {
        login: {
            auth: false,
            directory: 'containers/Login/Login',
            name: '登录',
            path: '/login'
        }
    },
    layout: {
        login: {
            auth: false,
            directory: 'layouts/LoginLayout',
            exact: false,
            name: '登录布局',
            path: '/login'
        },
        base: {
            directory: 'layouts/BasicLayout',
            exact: false,
            name: '基础布局',
            path: '/'
        }
    }
};


export const BasicRouter: any = JSON.parse(JSON.stringify(Router.basic).replace(/\/:\w+"/g, '/"').replace(/\/:\w+\?/g, '/'))
