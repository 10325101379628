import React from 'react';
import { Switch, Route, Router, Redirect } from 'react-router-dom';
import logo from './logo.svg';
import history from './utils/history';
import {StoreContext} from 'redux-react-hook';
import { getRouterData } from './router/router';
import { LocaleProvider } from 'antd';
import zhCN from 'antd/lib/locale-provider/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';

// 公共样式
import './assets/styles/index.less';
import './assets/styles/themes/default.less'

moment.locale('zh-cn');
// interface IRoute {
//   path: string,
//   exact: boolean,
//   render: any
// }

export default () => {
  const routers: Array<object> = getRouterData('layout');

  return (
      <StoreContext.Provider value={{}}>
        <Router history = {history}>
          <LocaleProvider locale={zhCN}>
            <Switch>
              {
                routers.map((route: any, index: number) => (
                  <Route
                    key={index}
                    path={route.path}
                    component={route.render}
                  />
                ))
              }
              {/* TODO： 未来添加404 */}
              {/* <Redirect from="*" to="/"></Redirect> */}
            </Switch>
          </LocaleProvider>
        </Router>
      </StoreContext.Provider>
  )
}
