import React from "react";
// import Store from "store";
// import { Redirect } from "react-router-dom";
import Loadable from "react-loadable";
import { Router } from "./routes";
import { Alert } from "antd";
// import { setPageTitle } from "./index";

/**
 * 占位显示 “Loading” 状态的组件，不需要可返回null
 * @param {*} props 加载组件的属性
 * @param {*} title 页面标题
 * @param {*} authorize 是否需要登录
 */
// export const loadingComponent = ({ isLoading, error }, title: string, authorize: boolean) => {
//     setPageTitle(title); // 设置页面标题
//     if (authorize) {
//         const token = Store.get(Config.store.token);
//         if (!token) {
//             return <Redirect to="/login" />;
//         }
//     }
//     if (isLoading) {
//         // 正在加载
//         return null;
//     } else if (error) {
//         // 页面加载失败
//         return <Alert message="对不起，页面加载失败。" type="error" />;
//     } else {
//         return null;
//     }
// };

// 获取路由
export const getRouterData = (app: string) => {
    const arr = Object.values(Router[app]).map((item: any, index: number) => ({
        parentPath: item.parentPath,
        path: item.path,
        exact: item.exact === undefined ? true : item.exact,
        render: Loadable({
            loader: () => import(`../${item.directory}`),
            loading: () => null
            // (props: any) => loadingComponent(
            //       props,
            //       item.name,
            //       item.auth === undefined ? true : item.auth
            //   )
        })
    }));
    return arr;
};

// 获取可用路由 TODO:待优化
// export const getUsableRouter = menuDatas => {
//     const routers = getRouterData('basic');
//     Store.set(Config.store.basicRouter, routers);
//     const funcs = menuDatas && menuDatas.funcs;
//     const menus = menuDatas && menuDatas.menus;
//     const matchs = [];
//     if ((!funcs && (!menus || menus.length === 0)) || (!menus && (!funcs || funcs.length === 0))) return;
//     funcs.forEach(menu => {
//         routers.forEach(function(item, index) {
//             if (item.path === this.funcUrl) {
//                 matchs.push(item);
//                 routers.splice(index, 1);
//             }
//         }, menu);
//     });
//     menus.forEach(menu => {
//         routers.forEach(function(item, index) {
//             if (item.path === this.menuUrl) {
//                 matchs.push(item);
//                 routers.splice(index, 1);
//             }
//         }, menu);
//     });
//     matchs.forEach(match => {
//         routers.forEach(function(item) {
//           if (item.parentPath === this.path){
//             matchs.push(item);
//           }
//         }, match);
//     });
//     return matchs;
// };
